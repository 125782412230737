var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fixed-center text-center" },
    [
      _c("q-icon", {
        staticClass: "hero",
        attrs: { name: "ion-wallet", size: "102px" }
      }),
      _c("h1", { staticClass: "text-system-brand text-weight-medium" }, [
        _vm._v("\n    Wallet Funded.\n  ")
      ]),
      _vm._m(0),
      _c(
        "p",
        {
          staticClass:
            "text-weight-semibold inline-block text-primary font-size-100p"
        },
        [_vm._v("You may now close this window and return to app.")]
      ),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "q-btn",
            {
              staticClass:
                "text-weight-semibold inline-block text-secondary font-size-100p",
              on: {
                click: function($event) {
                  return _vm.returnToApp()
                }
              }
            },
            [
              _c("q-icon", {
                staticClass: "on-left-sm",
                attrs: { name: "ion-arrow-back" }
              }),
              _c("span", { staticClass: "text-system-brand" }, [
                _vm._v("Or Return to App")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-faded" }, [
      _c("strong", [_vm._v("Congratulations!")]),
      _vm._v(" Your payment was successful.\n  ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="fixed-center text-center">
    <q-icon name="ion-wallet" class="hero" size="102px"/>
    <!-- <img class="hero" src="~assets/sad.svg"> -->
    <h1 class="text-system-brand text-weight-medium">
      Wallet Funded.
    </h1>
    <p class="text-faded">
      <strong>Congratulations!</strong> Your payment was successful.
    </p>
    <p class="text-weight-semibold inline-block text-primary font-size-100p">You may now close this window and return to app.</p>

    <div class="text-center">
      <q-btn class="text-weight-semibold inline-block text-secondary font-size-100p" @click="returnToApp()">
        <q-icon name="ion-arrow-back" class="on-left-sm"/>
        <span class="text-system-brand">Or Return to App</span>
      </q-btn>
    </div>

  </div>
</template>

<script>
// Confetti
import VueConfetti from 'vue-confetti'

export default {
  name: 'PageWalletSuccess',
  components: {
    VueConfetti
  },
  data () {
    return {}
  },
  mounted () {
    setTimeout(this.confetti_start, 200)
  },
  methods: {
    returnToApp () {
      window.location.href = '/?r=' + this.$guid.noHyphen(this.$guid.generate()) + '#wallet'
    },
    confetti_start () {
      this.$confetti.start({
        particles: 20,
        windSpeedMax: 2
      })
      setTimeout(this.confetti_stop, 5000)
    },
    confetti_stop () {
      this.$confetti.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
h1
  font-size 200%
  margin-top 0
  margin-bottom 10px
img
  &.hero
    display inline-block
    padding-bottom 20px
    width 100%
    max-width 140px
code
  font-size 80%
  display block
  width 80vw
  max-width 80vw
  white-space normal
.q-btn
  width 200px
  margin 20px
</style>
